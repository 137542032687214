export const organizationSchema = JSON.stringify({
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "AR-Labs.io",
  description:
    "Augmented reality software solutions for the eyewear manufacturers, distributors and stores",
  url: "https://ar-labs.io/",
})

export const faqSectionSchema = JSON.stringify({
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "What is Augmented Reality (AR)?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Augmented Reality (AR) combines the real and cirtual worlds based on digital elements (visual, sounds, sensory stimulai) delivered by technology in th real, physical world. AR technology and methods integrate device that films the real world and 3D models added on the recorded image in real time. You don't need special equipment to see AR elements on your phone, computer, or other AR devices (as a virtual mirror, for example)",
      },
    },
    {
      "@type": "Question",
      name: "What is needed for AR?",
      acceptedAnswer: {
        "@type": "Answer",
        text: " You need only a device with the camera - a mobile phone, computer, or other devices (as a virtual mirror, for example). ",
      },
    },
    {
      "@type": "Question",
      name: "How is AR used in business?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Augmented reality can deliver products or information in real-time to the customers to support their experience with the brand. AR software is used in many industries for various purposes - from education or training to marketing activities.",
      },
    },
    {
      "@type": "Question",
      name: "What is Virtual Try-On (VTO)?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Virtual Try-on is a type of technology that allows users to interact with digital items by means of implementation of augmented reality features. The image (in our case the 3D model) is generated onto the screen and uses tracking software in order to anchor the model where its supposed to go. Virtual try on works best in real time using cameras and screens creating something resembling a combination of a mirror and a screen.",
      },
    },
    {
      "@type": "Question",
      name: "What problems does Virtual Try-On solve?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Virtual try-on solutions are beneficial no matter what difficulties your company is currently facing. Whether its high returns, lack of proper product representation, unstatisfational numbers in sales or anything else, we're here to help and show you how to use virtual try-on to solve just about any issue!",
      },
    },
  ],
})
