import React from "react"
import { imageBackgroundStyle } from "../../const/image"
import { t } from "../../utils/translationUtils"
import { languages } from "../../const/languages"

const Cover = ({lang = languages.EN}) => {
  const [text, setText] = React.useState();
  React.useEffect(() => {
    setText(() => {return require(`../../translations/possibilities/${lang}.json`).cover})
  }, [])

  return (
    text &&
    <div
      className="card solutions-cover-card"
      style={imageBackgroundStyle("solutions-cover-card")}
      loading="eager"
    >
      <div className="container">
        <div className="title-header">
          <h1 className="light">
            {t(text.title)}
          </h1>
          <h4 className="light">
            {t(text.subtitle)}
          </h4>
        </div>
      </div>
    </div>
  )
}

export default Cover
