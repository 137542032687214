import imageAVIF from "../assets/images/avif/logo-small.avif"
import imageWEBP from "../assets/images/webp/logo-small.webp"

const checkFormat = async () => {
  const avifblob = await fetch(imageAVIF).then(r => r.blob())
  const result = await createImageBitmap(avifblob)
    .then(() => "avif")
    .catch(async () => {
      const webpblob = await fetch(imageWEBP).then(r => r.blob())
      return createImageBitmap(webpblob).then(() => "webp")
    })
    .catch(() => "png")

  return result
}

export { checkFormat }
