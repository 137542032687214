import * as React from "react"
import Button from "../../common/button/button"
import { imageBackgroundStyle } from "../../const/image"
import { Links } from "../../const/links"
import {
  checkCookieConsent,
  setCookieConsent,
} from "../../services/cookiesConsent"
import { t } from "../../utils/translationUtils"
import { languages } from "../../const/languages"

const CookiesPrompt = ({lang = languages.EN}) => {
  const [text, setText] = React.useState();
  const [hideCookie, setHideCookie] = React.useState()
  
  React.useEffect(() => {
    setText(() => { return require(`../../translations/landing/${lang}.json`).cookiesPrompt})
  }, [])

  React.useEffect(() => {
    setHideCookie(checkCookieConsent())
  }, [])

  React.useEffect(() => {
    if (hideCookie) {
      hidePrompt()
    }
  }, [hideCookie])

  const hidePrompt = () =>
    document.getElementById("cookies-prompt-card") 
    ? document.getElementById("cookies-prompt-card").classList.add("hidden")
    : "";

  const acceptCookies = () => {
    setCookieConsent()
    hidePrompt()
  }

  return (
    text && 
    <div
      className={`cookies-prompt-card ${hideCookie ? 'hidden' : ''}`}
      id="cookies-prompt-card"
      style={imageBackgroundStyle("card-gradients-cookies")}
    >
      <div>
        <div
          className="container"
          style={{ paddingTop: "40px", paddingBottom: "0" }}
        >
          <div className="body-1 bold" style={{ marginBottom: "20px" }}>
            {t(text.title)}
          </div>
          <div className="grid-template-2-columns">
            <div className="grid-container" style={{ marginBottom: "40px" }}>
              <div className="body-2 light">
                {t(text.text1)}
              </div>
              <div className="body-2 light">
                {t(text.text2)}
              </div>
            </div>
            <div className="grid-container cookies-button-container">
              <Button
                text={t(text.button_accept)}
                className="button button-dark"
                onClick={() => acceptCookies()}
              />
              <Button
                text={t(text.button_privacy_policy)}
                className="button button-light"
                onClick={() => {
                  const link = lang === languages.EN ? Links.privacyPolicy : Links[`privacyPolicy_${lang}`];
                  window.open(link)
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CookiesPrompt
