import * as React from "react"
import OurSolutions from "../components/ourSolutions/ourSolutions"
import Seo from "../components/seo"
import "../assets/css/style.css"
import "../assets/css/layout.css"
import "../assets/css/solutions-style.css"
import { organizationSchema } from "../const/schemas"
import { languages } from "../const/languages"

const ServicesPage = () => <OurSolutions lang={languages.EN} />

export const Head = () => {
  return (
    <>
      <Seo
        title="AR-Labs.io | Choose the best AR solution for your business."
        description="Let us make augmented reality your reality. It’s time to launch your first virtual try-on or other AR solution as a perfect answer to your customers’ needs."
        canonicalURL="https://ar-labs.io/possibilities"
      />
      <script type="application/ld+json">{organizationSchema}</script>
    </>
  )
}

export default ServicesPage
