import * as React from "react"
import Header from "../landing/header"
import { checkFormat } from "../../common/checkFormat"
import CookiesPrompt from "../landing/cookiesPrompt"
import Cover from "./cover"
import scrollTo from "gatsby-plugin-smoothscroll"

const Footer = React.lazy(() => import("../landing/footer"))
const Contact = React.lazy(() => import("../landing/contact/contact"))
//const Solution = React.lazy(() => import("./solutions"))
const BackToTopButton = React.lazy(() => import("../landing/backToTopButton"))
const AugmentReality = React.lazy(() => import("./augmentReality"))

const OurSolutions = ({lang}) => {
  const [loader, setLoader] = React.useState(true)
  const [currId, setCurrId] = React.useState("")

  React.useEffect(() => {
    if (window) {
      checkFormat().then(result => {
        window.localStorage.setItem("images-extension", result)
        setCurrId(window.location.hash)
        setLoader(false)
      })
    }
  }, [loader])

  React.useEffect(() => {
    if (window) {
      checkFormat().then(result => {
        window.localStorage.setItem("images-extension", result)
        setLoader(false)
      })
    }
  }, [])

  React.useEffect(() => {
    if (currId) {
      scrollTo(`${currId}`)
    }
  }, [currId])

  return (
    <>
      {!loader ? (
        <>
          <div id="top" />
          <Header  lang={lang} />
          <div className="content">
            <CookiesPrompt lang={lang} />
            <Cover lang={lang} />
            <AugmentReality lang={lang} />
            {/* <Solutions/> */}
            <Contact lang={lang}/>
            <Footer  lang={lang} />
            <BackToTopButton  lang={lang} />
          </div>
        </>
      ) : (
        ""
      )}
    </>
  )
}

export default OurSolutions
