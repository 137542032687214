const ARLABS_COOKIE_CONSENT = "ARLABS_COOKIE_CONSENT"

export const checkCookieConsent = () => {
  return window.localStorage.getItem(ARLABS_COOKIE_CONSENT)
}

export const setCookieConsent = () =>
  window.localStorage.setItem(
    ARLABS_COOKIE_CONSENT,
    "Cookie consent for AR-Labs.io"
  )
